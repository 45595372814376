<template>
  <div>
    <AppModal
      :dialogTitle="modalTitle"
      :disableClose="true">
      <div v-if="action !== 'change'">
        <p class="text-center">
          Enter Pin
        </p>
        <div class="pin-container text-center">
          <CodeInput
            class="input"
            className="pincode"
            :loading="false"
            :fields="4"
            :autoFocus="true"
            @change="onChange"
            @complete="onComplete"
          />
        </div>

        <div class="button-wrapper">
          <b-button
            class="btn-h3-orange"
            block
            :disabled="!isComplete"
            @click="setPinCode">
            Submit
          </b-button>
        </div>
      </div>

      <div v-else>
        <!-- Change Pin -->
        <div v-show="!correctCurrPin">
          <p class="text-center">Enter Current Pin</p>
          <div class="pin-container text-center">
            <CodeInput
              class="input"
              className="pincode"
              :loading="false"
              :fields="4"
              :autoFocus="true"
              @change="onChangeCurrPin"
              @complete="onCompleteCurrPin"
            />
          </div>
          <div class="button-wrapper">
            <b-button
              class="btn-h3-orange"
              block
              :disabled="!isCurrComplete"
              @click="checkPin">
              Submit
            </b-button>
          </div>
        </div>

        <div v-show="correctCurrPin">
          <p class="text-center">Enter New Pin</p>
          <div class="pin-container text-center">
            <CodeInput
              class="input"
              className="pincode"
              :loading="false"
              :fields="4"
              :autoFocus="true"
              @change="onChange"
              @complete="onComplete"
            />
          </div>
          <div class="button-wrapper">
            <b-button
              class="btn-h3-orange"
              block
              :disabled="!isComplete"
              @click="setPinCode">
              Submit
            </b-button>
          </div>
        </div>
      </div>
    </AppModal>
    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  export default {
    name  : 'PinModal',
    props : {
      action : {
        type     : String,
        required : true,
      },
      redirect : {
        type    : Boolean,
        default : true,
      },
      redirection : {
        type    : String,
        default : '/manage-profiles',
      },
    },
    data() {
      return {
        loading        : false,
        isComplete     : false,
        pinCode        : null,
        currPinCode    : null,
        correctCurrPin : false,
        isCurrComplete : false,
      }
    },
    computed : {
      modalTitle() {
        let title = null;
        switch (this.action) {
        case 'add':
          title = 'Add Pin';
          break;
        case 'check':
          title = 'Check Pin';
          break;
        default:
          title = 'Change Pin';
          break;
        }

        return title;
      },
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
      'AppModal'  : () => import('@/components/layout/AppModal'),
      'CodeInput' : () => import('vue-verification-code-input'),
    },
    methods : {

      /**
       * Pin Change
       * @param value
       */
      onChange(value) {
        this.pinCode = value;
        this.isComplete = false;
      },

      /**
       * Pin Complete
       * @param value
       */
      onComplete(value) {
        this.pinCode = value;
        this.isComplete = true;
      },

      /**
       * Current Pin Change
       * @param value
       */
      onChangeCurrPin(value) {
        this.currPinCode = value;
        this.isCurrComplete = false;
      },

      /**
       * Current Pin Complete
       * @param value
       */
      onCompleteCurrPin(value) {
        this.currPinCode = value;
        this.isCurrComplete = true;
      },

      /**
       * Set Parent's Pin Code
       */
      setPinCode() {
        switch (this.action) {
        case 'add':
          this.addPin();
          break;
        case 'check':
          this.checkPin();
          break;
        default:
          this.changePin();
          break;
        }
      },

      /**
       * Add Pin Code
       */
      addPin() {
        this.loading = true;
        this.$http.post('api/user/parents/pin', {
          pincode : this.pinCode,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Pin Code set successfully!',
          });
          this.$emit('close');
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },

      /**
       * Change Pin Code
       */
      changePin() {
        this.loading = true;
        this.$http.put('api/user/parents/pin', {
          pincode : this.pinCode,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Pin Code changed successfully!',
          });
          this.$emit('close');
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },

      /**
       * Check if Pin is correct
       */
      checkPin() {
        this.loading = true;
        this.$http.get('api/user/parents/pinCheck', {
          params : {
            pincode : (this.action ==='change') ?
              this.currPinCode : this.pinCode,
          },
        }).then(response => {
          if (response.data.result) {
            if (this.action === 'change')
              this.correctCurrPin = true;
            else {
              this.$emit('close');
              this.$store.dispatch('auth/setPinVer', true);

              if (this.redirect)
                this.$router.push(this.redirection);
              else
                this.$parent.$emit('pin-correct', true);
            }
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Pin Code is correct!',
            });
          } else {
            if (this.action === 'change')
              this.correctCurrPin = false;

            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Pin Code is incorrect!',
            });
          }
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .pincode {
    margin: 0 auto;
    width: auto;
  }

  .react-code-input {
    input {
      font-family: 'ArialRoundedMTExtraBold' !important;
    }
  }

  .button-wrapper {
    margin: 2rem auto 0 auto;
    width: 70%;
  }
</style>